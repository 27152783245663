
  import ModalWrapper from '@/components/modals/ModalWrapper.vue';
  import { defineComponent } from 'vue';
  import CommodityGroupsParamItem from "@/components/commodity-groups/CommodityGroupsParamItem.vue";
  import product from "@/services/product";

  export default defineComponent({
    name: 'EditProductSubGroupModal',
    components: { ModalWrapper, CommodityGroupsParamItem },
    data: () => ({
      changesCommodityGroupsParam: {
        name: null,
        description: null,
      } as any
    }),
    props: {
      productSubGroup: {
        type: Object,
        default: () => null
      }
    },
    computed: {
      checkValues(): any {
        return Object.values(this.changesCommodityGroupsParam).every(el => el === null)
      }
    },
    methods: {
      changeValue(field: string, value: string) {
        this.changesCommodityGroupsParam[field] = value
      },
      saveChanges() {
        const payload = {
          ...this.productSubGroup,
          supplierId: null,
          ...(this.changesCommodityGroupsParam.name && {name: this.changesCommodityGroupsParam.name}),
          ...(this.changesCommodityGroupsParam.description && {description: this.changesCommodityGroupsParam.description}),
        }

        product.updateProductSubSubGroup(this.productSubGroup.id, payload).then((response: any) => {
          this.$emit('reload', response.data)
          this.$emit('closeModal')
        })
      }
    },
    async mounted() {
    }
  })
