
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import { defineComponent } from 'vue';
import BaseInputField from '@/components/modals/base-modals/base-components-modal/BaseInputField.vue';

export default defineComponent({
  name: 'BaseUpdateItemModal',
  emits: ['closeModal', 'updateItem', 'getInfoField'],
  components: { ModalWrapper, BaseInputField },
  data: () => ({
    error: null as any,
    tempData: {} as any,
  }),
  props: {
    id: {
      type: String,
      default: () => 'base-update-item'
    },
    btnName: {
      type: String,
      default: () => 'Добавить'
    },
    itemData: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: () => ''
    },
    getInfoField: {
      type: Function,
      default: () => {}
    },
  },
  watch: {
    itemData: {
      handler() {
        this.tempData = { ...this.itemData }
      },
      deep: true
    }
  },
  methods: {
    getName(field: string) {
      return this.getInfoField(field)
    },
    setDefault() {
      this.tempData = { ...this.itemData }
    },
    closeModal() {
      this.setDefault()

      const modalId = `modal-${this.id}`
      this.$emit('closeModal', modalId)
    },
    setValue(fieldName: string, value: string) {
      this.tempData[fieldName] = value
    },
    async updateItem() {
      this.$emit('updateItem', this.tempData)
      this.closeModal()
    },
  },
  mounted() {
    this.setDefault()
  }
})
