
import { defineComponent } from 'vue';
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import SearchDropdown from '@/components/search/SearchDropdown.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import product from '@/services/product';
import Spinner from '@/components/Spinner.vue';

export default defineComponent({
  name: 'TecDocConnectionsModal',
  components: { Spinner, BaseIcon, SearchDropdown, ModalWrapper },
  props: {
    productGroup: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    searchText: '' as string,
    selectedTecDocProductGroup: [] as Array<number | string>,
    isLoading: false as boolean
  }),
  methods: {
    update(selectedProductGroup: any) {
      if (selectedProductGroup) {
        const isProductGroupAlreadySelected = this.selectedTecDocProductGroup.find((productGroup: any) => {
          return selectedProductGroup.tecdocId === productGroup.tecdocId
        })

        if (isProductGroupAlreadySelected) return

        this.searchText = selectedProductGroup && selectedProductGroup.desc
        this.selectedTecDocProductGroup.push(selectedProductGroup)
      }
    },
    deleteSelectedProductGroup(currentProductGroup: any) {
      this.selectedTecDocProductGroup = this.selectedTecDocProductGroup.filter((productGroup: any) => {
        return productGroup.id !== currentProductGroup.id
      })
    },
    async setTecDocIds() {
      const tecDocIds = this.selectedTecDocProductGroup.map((pg: any) => pg.tecdocId)
      this.$emit('setTecdocIds', tecDocIds)
    }
  },
  async mounted() {
    if (this.productGroup && this.productGroup.tecdocIds && this.productGroup.tecdocIds.length) {
      this.isLoading = true
      this.selectedTecDocProductGroup = await product.searchProductGroupByTecdocIds(this.productGroup.tecdocIds)
      this.isLoading = false
    }
  }
});
