
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import { defineComponent } from 'vue';
import CommodityGroupsItem from '@/components/commodity-groups/CommodityGroupsItem.vue';
import brand from "@/services/brand";
import BaseError from "@/components/ui/BaseError.vue";
import BaseCheckbox from "@/components/ui/BaseCheckbox.vue";
import CommodityGroupFieldItem from "@/components/commodity-groups/CommodityGroupFieldItem.vue";
import product from "@/services/product";

export default defineComponent({
  name: 'AddCommodityGroupModal',
  components: { ModalWrapper, CommodityGroupFieldItem },
  emits: ['setCommodityGroup'],
  data: () => ({
    error: null as any,
    dataNewCommodityGroup: {
      desc: null as any,
    } as any
  }),
  methods: {
    setDataCommodityGroup(fieldName: string, value: string) {
      this.dataNewCommodityGroup[fieldName] = value
    },
    async addCommodityGroup() {
      const newCommodityGroup = await product.addNewProductGroup({
        desc: this.dataNewCommodityGroup.desc,
        descTecDoc: this.dataNewCommodityGroup.descTecDoc,
        desigDesc: this.dataNewCommodityGroup.desigDesc,
        cid: localStorage.getItem('cid')
      })

      this.$emit('setCommodityGroup', newCommodityGroup)
    }
  },
})
