
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import { defineComponent } from 'vue';
import CommodityGroupFieldItem from "@/components/commodity-groups/CommodityGroupFieldItem.vue";
import product from "@/services/product";

export default defineComponent({
  name: 'AddProductSubGroupModal',
  components: { ModalWrapper, CommodityGroupFieldItem },
  emits: ['setCommoditySubGroups', 'closeModal'],
  data: () => ({
    error: null as any,
    dataNewCommodityGroup: {
      desc: null as any,
      descShort: null,
    } as any
  }),
  props: {
    commodityGroupId: {
      type: Number,
      required: false,
      default: () => null
    }
  },
  methods: {
    setDataCommodityGroup(fieldName: string, value: string) {
      this.dataNewCommodityGroup[fieldName] = value
    },
    async addCommodityGroup() {
      const data = await product.addNewProductSubGroup({
        cid: localStorage.getItem('cid'),
        supplierId: 10,
        desc: this.dataNewCommodityGroup.desc,
        descShort: this.dataNewCommodityGroup.descShort,
        productGroupId: this.commodityGroupId || this.$route.params.id,
        usage: true
      })

      this.dataNewCommodityGroup = { desc: null, descShort: null }

      this.$emit('setCommoditySubGroups', data)
      this.$emit('closeModal')
    }
  },
})
