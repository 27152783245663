
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import { defineComponent } from 'vue';
import brand from "@/services/brand";
import BaseError from "@/components/ui/BaseError.vue";
import BaseCheckbox from "@/components/ui/BaseCheckbox.vue";
import CommodityGroupFieldItem from "@/components/commodity-groups/CommodityGroupFieldItem.vue";
import product from "@/services/product";

export default defineComponent({
  name: 'AddProductSubGroupModal',
  components: { ModalWrapper, CommodityGroupFieldItem },
  emits: ['setCommoditySubGroups', 'closeModal'],
  data: () => ({
    error: null as any,
    dataNewCommodityGroup: {
      name: null as any,
      description: null,
    } as any
  }),
  props: {
    subProductGroupId: {
      type: Number,
      default: () => null,
      required: false
    }
  },
  methods: {
    setDataCommodityGroup(fieldName: string, value: string) {
      this.dataNewCommodityGroup[fieldName] = value
    },
    async addCommodityGroup() {
      const data = await product.addNewProductSubSubGroup({
        cid: localStorage.getItem('cid'),
        supplierId: 10,
        name: this.dataNewCommodityGroup.name,
        description: this.dataNewCommodityGroup.description,
        productSubGroupId: this.subProductGroupId || Number(this.$route.params.id),
      })

      this.$emit('setCommoditySubGroups', data)
      this.$emit('closeModal')
    }
  },
})
