
import { defineComponent } from 'vue';
import product from '@/services/product';
import AddProductSubSubGroupModal from '@/components/modals/AddProductSubSubGroupModal.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import EditProductSubSubGroupModal from '@/components/modals/EditProductSubSubGroupModal.vue';
import SelectElementWithContentModal from '@/components/modals/SelectElementWithContentModal.vue';
import TecDocConnectionsModal from '@/components/modals/TecDocConnectionsModal.vue';
import { store } from '@/store';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';
import DropdownEllipsis from '@/components/admin-panel/DropdownEllipsis.vue';

export default defineComponent({
  name: 'CommoditySubSubGroupTree',
  components: { DropdownEllipsis, TecDocConnectionsModal, AddProductSubSubGroupModal, EditProductSubSubGroupModal, SelectElementWithContentModal, BaseIcon },
  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: () => false
    },
    commoditySubGroup: {
      type: Object,
      required: true
    },
    isListOpen: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    commoditySubSubGroups: [] as any,
    selectedSubProductGroup: null as any,
    isShowModalTecDocConnections: false
  }),
  methods: {
    async setTecdocIds(pgIds: Array<number | string>) {
      try {
        const newProductGroup = await product.setSubSubGroupTecdocIds(this.selectedSubProductGroup.id, pgIds)
        this.replaceCommodityGroups(newProductGroup)
        this.closeModalTecDocConnections()
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS');
      } catch (error) {
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
      }
    },
    closeModalTecDocConnections() {
      this.selectedSubProductGroup = null
      this.isShowModalTecDocConnections = false
      let element = document.getElementById(`modal-tec-doc-connections`)!;
      element.style.display = 'none'
    },
    openModalTecDocConnections(group: any){
      this.selectedSubProductGroup = group
      this.isShowModalTecDocConnections = true

      setTimeout(() => {
        let element = document.getElementById(`modal-tec-doc-connections`)!;
        element.style.display = 'flex'
      }, 0)
    },
    async deleteCommodityGroup() {
      try {
        await product.deleteSubSubProductGroup(this.selectedSubProductGroup.id)
        this.deleteGroup(this.selectedSubProductGroup)
        this.closeModalConfirmDelete()
      } catch (e) {}
    },
    deleteGroup(newCommodityGroup: any) {
      const index = this.commoditySubSubGroups.findIndex((el: any) => el.id === newCommodityGroup.id)
      this.commoditySubSubGroups.splice(index, 1)
    },
    openModalConfirmDelete(subSubGroup: any) {
      this.selectedSubProductGroup = subSubGroup
      setTimeout(() => {
        let element = document.getElementById(`modal-select-element-with-content-${this.selectedSubProductGroup.id}`)!;
        element.style.display = 'flex'
      })
    },
    closeModalConfirmDelete() {
      let element = document.getElementById(`modal-select-element-with-content-${this.selectedSubProductGroup.id}`)!;
      element.style.display = 'none'
      this.selectedSubProductGroup = null
    },
    replaceCommodityGroups(newCommodityGroup: any) {
      const index = this.commoditySubSubGroups.findIndex((el: any) => el.id === newCommodityGroup.id)
      this.commoditySubSubGroups.splice(index, 1, newCommodityGroup)
    },
    openModalEditSubProductGroup(group: any) {
      this.selectedSubProductGroup = group

      setTimeout(() => {
        let element = document.getElementById(`modal-edit-product-sub-sub-group`)!
        element.style.display = 'flex'
      }, 0)
    },
    closeModalEditProductSubGroup() {
      this.selectedSubProductGroup = null
      let element = document.getElementById(`modal-edit-product-sub-sub-group`)!
      element.style.display = 'none'
    },
    openModal(group: any) {
      this.selectedSubProductGroup = group

      setTimeout(() => {
        let element = document.getElementById(`modal-add-sub-sub-group-${this.commoditySubGroup.id}`)!
        element.style.display = 'flex'
      }, 0)
    },
    closeModal() {
      this.selectedSubProductGroup = null
      let element = document.getElementById(`modal-add-sub-sub-group-${this.commoditySubGroup.id}`)!
      element.style.display = 'none'
    },
    addCommoditySubSubGroup(commoditySubSubGroup: any) {
      this.commoditySubSubGroups.push(commoditySubSubGroup)
      this.closeModal()
    }
  },
  async mounted() {
    if (this.isListOpen) {
      this.commoditySubSubGroups = await product.findAllProductSubSubGroupByProductId(this.commoditySubGroup.id)
    }
  }
})
