
import {defineComponent, computed} from 'vue';
import Spinner from '@/components/Spinner.vue';
import BaseIcon from "@/components/ui/BaseIcon.vue";
import DropdownSearchCommodityGroups from "@/components/dropdown/DropdownSearchCommodityGroups.vue";
import product from "@/services/product";
import CommodityGroupTree from '@/components/commodity-group-tree/CommodityGroupTree.vue';
import tree from '@/services/tree';
import timeService from '@/services/timeService';
import { store } from '@/store';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';

export default defineComponent({
  name: 'CommodityGroups',
  components: {
    CommodityGroupTree,
    Spinner,
    BaseIcon,
    DropdownSearchCommodityGroups,
  },
  data: () => ({
    searchLetter: 'Все' as any,
    isFoundCommodityGroups: false as boolean,
    pageable: null as any,
    currentGroup: null as any,
    isLoading: true as boolean,
    commodityGroups: [] as any,
    arrSequentialValues: ['А', 'Б', 'В', 'Г', 'Д', 'Е', 'Ё', 'Ж', 'З', 'И', 'Й', 'К', 'Л', 'М', 'Н', 'О', 'П', 'Р', 'С', 'Т', 'У', 'Ф', 'Ч', 'Ц', 'Ш', 'Щ', 'Ъ', 'Ы', 'Ь', 'Э', 'Ю', 'Я'],
  }),
  methods: {
    async downloadTreeGroups() {
      try {
        const table = await tree.exportTreeGroups()
        let date = timeService.createDateForViewFromUTCTime();
        const blob = new Blob([table], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}) as any;
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `Системы_${date}.xls`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
      }
    },
    async setFoundCommodity(data: {inputValue: string, searchResult: any}) {
      if (!data.inputValue.length) {
        this.commodityGroups = await this.getCommodityGroups()
      } else {
        this.commodityGroups = data.searchResult
      }
    },
    setNewCommodityGroup(commodityGroup: any) {
      this.commodityGroups.push(commodityGroup)
      this.closeModalAddCommodityGroup()
    },
    openModalAddCommodityGroup() {
      let element = document.getElementById(`modal-add-commodity-group`)!
      element.style.display = 'flex'
    },
    closeModalAddCommodityGroup() {
      let element = document.getElementById(`modal-add-commodity-group`)!
      element.style.display = 'none'
    },
    previous() {
      if (!!this.pageable.pageNumber) {
        if (this.searchLetter && this.searchLetter !== 'Все') {
          this.setCommodityGroups(this.pageable.pageNumber - 1, {
            action: 'SEARCH_BY_LETTER',
            letter: this.searchLetter
          })

          return
        }
        this.setCommodityGroups(this.pageable.pageNumber - 1)
      }
    },
    next () {
      if ((this.pageable.pageNumber + 1) !== this.pageable.totalPages) {
        if (this.searchLetter && this.searchLetter !== 'Все') {
          this.setCommodityGroups(this.pageable.pageNumber + 1, {
            action: 'SEARCH_BY_LETTER',
            letter: this.searchLetter
          })

          return
        }
        this.setCommodityGroups(this.pageable.pageNumber + 1)
      }
    },
    replaceCommodityGroups(newCommodityGroup: any) {
      const index = this.commodityGroups.findIndex((el: any) => el.tecdocId === newCommodityGroup.tecdocId)
      this.commodityGroups.splice(index, 1, newCommodityGroup)
    },
    openCommodityGroupsModal(group: any) {
      this.currentGroup = group
      setTimeout(() => {
        let element = document.getElementById(`modal-commodity-groups-edit`)!
        element.style.display = 'flex'
      }, 0)
    },
    closeCommodityGroupsModal() {
      let element = document.getElementById(`modal-commodity-groups-edit`)!
      element.style.display = 'none'
    },
    async getCommodityGroups(page = 0, data?: any) {
      const newCommodityGroups = await product.findAllProductGroups(page).then((response: any) => {
        this.pageable = {
          ...response.pageable,
          totalPages: response.totalPages
        }

        return response.content
      })

      return newCommodityGroups
    },
    async setCommodityGroups(page = 0, data?: any) {
      this.isFoundCommodityGroups = false
      this.isLoading = true
      this.commodityGroups = await this.getCommodityGroups(page, data)
      this.isLoading = false
    },
  },
  async mounted() {
    this.setCommodityGroups()
  },
})
