
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import CommodityGroupsItem from "@/components/commodity-groups/CommodityGroupsItem.vue";
import articles from "@/services/articles";
import product from "@/services/product";

export default defineComponent({
  name: 'CommodityGroupsModal',
  components: { ModalWrapper, CommodityGroupsItem },
  data: () => ({
    changesCommodityGroup: {
      descTecDoc: null as any,
      tecdocId: null as any,
      desigDesc: null as any,
      assemblyDesc: null as any,
      desc: null as any,
    } as any
  }),
  props: {
    group: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    checkValues(): any {
      return Object.values(this.changesCommodityGroup).every(el => el === null)
    }
  },
  methods: {
    changeValue(field: string, value: string) {
      this.changesCommodityGroup[field] = value
    },
    saveChanges() {
      const payload = {
        ...this.group,
        ...(this.changesCommodityGroup.desc && {desc: this.changesCommodityGroup.desc}),
        ...(this.changesCommodityGroup.descTecDoc && {descTecDoc: this.changesCommodityGroup.descTecDoc}),
        ...(this.changesCommodityGroup.assemblyDesc && {assemblyDesc: this.changesCommodityGroup.assemblyDesc}),
      }

      product.updateProductGroup(this.group.id, payload).then((response: any) => {
        this.$emit('reload', response.data)
        this.$emit('closeModal')
      })
    }
  },
  async mounted() {

  }
})
