
import { defineComponent } from 'vue';
import product from '@/services/product';
import CommoditySubSubGroupTree
  from '@/components/commodity-group-tree/CommoditySubSubGroupTree.vue';
import ToggleItem from '@/components/commodity-group-tree/ToggleItem.vue';
import AddProductSubGroupModal from '@/components/modals/AddProductSubGroupModal.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import EditProductSubGroupModal from '@/components/modals/EditProductSubGroupModal.vue';
import SelectElementWithContentModal from '@/components/modals/SelectElementWithContentModal.vue';
import DropdownEllipsis from '@/components/admin-panel/DropdownEllipsis.vue';
import { store } from '@/store';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';
import TecDocConnectionsModal from '@/components/modals/TecDocConnectionsModal.vue';

export default defineComponent({
  name: 'CommoditySubGroupTree',
  components: {
    ToggleItem,
    CommoditySubSubGroupTree,
    AddProductSubGroupModal,
    BaseIcon,
    EditProductSubGroupModal,
    SelectElementWithContentModal,
    DropdownEllipsis,
    TecDocConnectionsModal
  },
  props: {
    isEdit: {
      type: Boolean,
      required: false,
      default: () => false
    },
    commodityGroup: {
      type: Object,
      required: true
    },
    isListOpen: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    commoditySubGroups: [] as any,
    selectedSubGroup: null as any,
    isShowModalTecDocConnections: false
  }),
  methods: {
    async setTecdocIds(pgIds: Array<number | string>) {
      try {
        const newProductGroup = await product.setSubGroupTecdocIds(this.selectedSubGroup.id, pgIds)
        this.replaceCommodityGroups(newProductGroup)
        this.closeModalTecDocConnections()
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS');
      } catch (error) {
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
      }
    },
    closeModalTecDocConnections() {
      this.selectedSubGroup = null
      this.isShowModalTecDocConnections = false
      let element = document.getElementById(`modal-tec-doc-connections`)!;
      element.style.display = 'none'
    },
    openModalTecDocConnections(group: any){
      this.selectedSubGroup = group
      this.isShowModalTecDocConnections = true

      setTimeout(() => {
        let element = document.getElementById(`modal-tec-doc-connections`)!;
        element.style.display = 'flex'
      }, 0)
    },
    async deleteCommodityGroup() {
      try {
        await product.deleteSubProductGroup(this.selectedSubGroup.id)
        this.deleteGroup(this.selectedSubGroup)
        this.closeModalConfirmDelete()
      } catch (e) {}
    },
    deleteGroup(newCommodityGroup: any) {
      const index = this.commoditySubGroups.findIndex((el: any) => el.id === newCommodityGroup.id)
      this.commoditySubGroups.splice(index, 1)
    },
    openModalConfirmDelete(subGroup: any) {
      this.selectedSubGroup = subGroup
      setTimeout(() => {
        let element = document.getElementById(`modal-select-element-with-content-${this.selectedSubGroup.id}`)!;
        element.style.display = 'flex'
      })
    },
    closeModalConfirmDelete() {
      let element = document.getElementById(`modal-select-element-with-content-${this.selectedSubGroup.id}`)!;
      element.style.display = 'none'
      this.selectedSubGroup = null
    },
    replaceCommodityGroups(newCommodityGroup: any) {
      const index = this.commoditySubGroups.findIndex((el: any) => el.id === newCommodityGroup.id)
      this.commoditySubGroups.splice(index, 1, newCommodityGroup)
    },
    closeModalEditProductSubGroup() {
      this.selectedSubGroup = null
      let element = document.getElementById(`modal-edit-product-sub-group`)!
      element.style.display = 'none'
    },
    openModalEditCommoditySubGroup(group: any) {
      this.selectedSubGroup = group
      setTimeout(() => {
        let element = document.getElementById(`modal-edit-product-sub-group`)!
        element.style.display = 'flex'
      }, 0)
    },
    openModal(group: any) {
      this.selectedSubGroup = group

      setTimeout(() => {
        let element = document.getElementById(`modal-add-sub-group-${this.commodityGroup.id}`)!
        element.style.display = 'flex'
      }, 0)
    },
    closeModal() {
      this.selectedSubGroup = null
      let element = document.getElementById(`modal-add-sub-group-${this.commodityGroup.id}`)!
      element.style.display = 'none'
    },
    addCommoditySubGroup(subGroup: any) {
      this.commoditySubGroups.push(subGroup)
      this.closeModal()
    },
  },
  async mounted() {
    if (this.isListOpen) {
      this.commoditySubGroups = await product.findAllProductSubGroupByProductId(this.commodityGroup.id)
    }
  }
})
