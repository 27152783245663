import {httpClient} from "@/httpClient";

// Карточка артикула (Входит в - состоит из - создание)
function createTree(payload: object) {
  return httpClient.post(`/article/${localStorage.getItem('cid')}/tree`, payload).then(response => response.data)
}

// Карточка артикула (Входит в - состоит из - добавление)
function addToTree(treeId: string, payload: object) {
  return httpClient.put(`/article/${localStorage.getItem('cid')}/tree/${treeId}`, payload).then(response => response.data)
}

// Карточка артикула (Входит в - состоит из - удаление целой ветки)
function deleteTree(treeId: string) {
  return httpClient.delete(`/article/${localStorage.getItem('cid')}/tree/${treeId}`).then(response => response.data)
}

// Карточка артикула (Входит в - состоит из - удаление ветки)
function deleteNode(treeId: string, nodeId: string) {
  return httpClient.delete(`/article/${localStorage.getItem('cid')}/tree/${treeId}/${nodeId}`).then(response => response.data)
}

function exportTreeGroups() {
  return httpClient.post(`/product/${localStorage.getItem('cid')}/tree/groups`, null, {
    responseType: 'blob'
  }).then(response=> response.data)
}

function exportTreeSystems() {
  return httpClient.post(`/product/${localStorage.getItem('cid')}/tree/systems`, null, {
    responseType: 'blob'
  }).then(response => response.data)
}

export default {
  createTree,
  addToTree,
  deleteTree,
  deleteNode,
  exportTreeGroups,
  exportTreeSystems,
}
