
import {defineComponent} from 'vue';
import CommodityGroups from "@/components/commodity-groups/CommodityGroups.vue";
import CommodityGroupsParam from "@/components/commodity-groups/CommodityGroupsParam.vue";
import BaseTabs from '@/components/ui/BaseTabs.vue';
import CommodityGroupsSettings from '@/components/commodity-groups/CommodityGroupsSettings.vue';
import Systems from '@/components/system-product-groups/Systems.vue';

export default defineComponent({
  name: 'CommodityGroupsAndParamsWrapper',
  components: {
    Systems,
    CommodityGroups,
    CommodityGroupsParam,
    CommodityGroupsSettings,
    BaseTabs
  },
  data: () => ({
    tabs: [
      { name: 'Товарные группы', query: 'commodity-groups'},
      { name: 'Системы', query: 'systems'},
      // { name: 'Все параметры', query: 'all-params'},
    ]
  }),
  computed: {
    systems(): any {
      return this.$store.getters.getSystemsProductGroups
    },
    isShowSettingProductGroups(): boolean {
      return this.$store.getters.getAllPermissions.edit_product_group[this.$store.getters.getUserRole]
    }
  },
  async mounted() {
    await this.$store.dispatch('fetchSystemsGroups')

    if (this.isShowSettingProductGroups) {
      this.tabs.push({ name: 'Настройка товарных групп', query: 'setting-commodity-groups'})
    }
  }
})
