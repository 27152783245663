
import { defineComponent } from 'vue';
import BaseIcon from "@/components/ui/BaseIcon.vue";

export default defineComponent({
  name: 'CommodityGroupsItem',
  data: () => ({
    dropdownValue: null as any,
    editMode: false,
    fieldValue: null,
    vehicleTypes: [] as any,
  }),
  components: {
    BaseIcon,
  },
  props: {
    field: {
      default: () => null
    },
    group: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    checkEditExceptions() {
      return (field: any) => {
        switch (field) {
          case 'tecdocId':
          case 'desigDesc':
          case 'descTecDoc':
          case 'brandId':
            return false
          default:
            return true
        }
      }
    },
    getInfoField() {
      return (field: any) => {
        switch (field) {
          case 'brandId':
            return `ID бренда (${field})`
          case 'brand':
            return `Название бренда (${field})`
          case 'desc':
            return `Описание (${field})`
          case 'descTecDoc':
            return `Текдок описание (${field})`
          case 'tecdocId':
            return `Текдок Id (${field})`
          case 'desigDesc':
            return `Описание сборки (${field})`
          default:
            return null
        }
      }
    }
  },
  methods: {
    changeFieldValue(value: any) {
      this.$emit('changeValue', this.field, value)
    },
    cancelChangesField() {
      this.changeFieldValue(null)
      this.editMode = false
    },
  },
})
