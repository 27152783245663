
import { defineComponent } from 'vue';
import ToggleItem from '@/components/commodity-group-tree/ToggleItem.vue';
import SystemSubSubGroups from '@/components/system-product-groups/SystemSubSubGroups.vue';
import DropdownEllipsis from '@/components/admin-panel/DropdownEllipsis.vue';
import { AttachSystemPayload } from '@/models/SystemModel';

export default defineComponent({
  name: 'SystemSubGroups',
  components: {
    ToggleItem,
    SystemSubSubGroups,
    DropdownEllipsis
  },
  props: {
    groups: {
      type: Object,
      default: () => {}
    },
    systemData: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    detachProductSubGroup(productSubGroup: any) {
      const payload = {
        pgs: [
          {
            pgId: this.groups.id,
            psgId: productSubGroup.id,
            pssgId: null,
          }
        ],
        systemId: this.systemData.system.id
      } as AttachSystemPayload

      this.$store.dispatch('detachSystem', payload)
    }
  }
})
