
import { defineComponent } from 'vue';
import ToggleItem from '@/components/commodity-group-tree/ToggleItem.vue';
import { AttachSystemPayload } from '@/models/SystemModel';
import DropdownEllipsis from '@/components/admin-panel/DropdownEllipsis.vue';

export default defineComponent({
  name: 'SystemSubSubGroups',
  components: {
    ToggleItem,
    DropdownEllipsis,
  },
  props: {
    groups: {
      type: Array,
      default: () => []
    },
    systemId: Number,
    productGroupId: Number,
    productSubGroupId: Number,
  },
  methods: {
    detachProductSubSubGroup(productSubSubGroup: any) {
      const payload = {
        pgs: [
          {
            pgId: this.productGroupId,
            psgId: this.productSubGroupId,
            pssgId: productSubSubGroup.id,
          }
        ],
        systemId: this.systemId
      } as AttachSystemPayload

      this.$store.dispatch('detachSystem', payload)
    }
  }
})
