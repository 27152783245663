
  import ModalWrapper from '@/components/modals/ModalWrapper.vue';
  import { computed, defineComponent, onMounted, ref, watch } from 'vue';
  import MasteringSheet from "@/services/MasteringSheet"
  import prices from "@/services/prices"
  import BaseCheckbox from "@/components/ui/BaseCheckbox.vue";
  import BaseIcon from '@/components/ui/BaseIcon.vue';
  import SearchDropdown from '@/components/search/SearchDropdown.vue';
  import SmallSpinner from '@/components/SmallSpinner.vue';
  import {ConvertDate} from "@/components/converters/date";
  import { GetUserName } from "@/services/authentication";
  import router from "@/router";
  import articles from "@/services/articles";
  import vehicles from "@/services/vehicles";
  import {MessageModalMutationTypes} from "@/store/modules/message-modal/mutation-types";
  import CommodityGroupsParamItem from "@/components/commodity-groups/CommodityGroupsParamItem.vue";
  import params from "@/services/params";
  import product from "@/services/product";

  export default defineComponent({
    name: 'EditProductSubGroupModal',
    components: { ModalWrapper, CommodityGroupsParamItem },
    data: () => ({
      changesCommodityGroupsParam: {
        desc: null,
        descShort: null,
      } as any
    }),
    props: {
      productSubGroup: {
        type: Object,
        default: () => null
      }
    },
    computed: {
      checkValues(): any {
        return Object.values(this.changesCommodityGroupsParam).every(el => el === null)
      }
    },
    methods: {
      changeValue(field: string, value: string) {
        this.changesCommodityGroupsParam[field] = value
      },
      saveChanges() {
        const payload = {
          ...this.productSubGroup,
          ...(this.changesCommodityGroupsParam.desc && {desc: this.changesCommodityGroupsParam.desc}),
          ...(this.changesCommodityGroupsParam.descShort && {descShort: this.changesCommodityGroupsParam.descShort}),
        }

        product.updateProductSubGroup(this.productSubGroup.id, payload).then((response: any) => {
          this.$emit('reload', response.data)
          this.$emit('closeModal')
        })
      }
    },
    async mounted() {
    }
  })
