
import {defineComponent} from "vue";
import IconArrowLeft from '@/components/icons/IconArrowLeft.vue';

export default defineComponent({
  name: 'ToggleItem',
  components: {
    IconArrowLeft
  },
  data: () => ({
    isListOpen: false as boolean
  }),
})
