
import { defineComponent } from 'vue';
import CommoditySubGroupTree from '@/components/commodity-group-tree/CommoditySubGroupTree.vue';
import ToggleItem from '@/components/commodity-group-tree/ToggleItem.vue';
import product from '@/services/product';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import SelectElementWithContentModal from '@/components/modals/SelectElementWithContentModal.vue';
import ProductGroupParam from '@/components/modals/ProductGroupParam.vue';
import DropdownEllipsis from '@/components/admin-panel/DropdownEllipsis.vue';
import params from '@/services/params';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';

export default defineComponent({
  name: 'CommodityGroupTree',
  emits: ['editCommodityGroup', 'replace', 'delete', 'openTecDocConnections'],
  components: {
    ProductGroupParam,
    BaseIcon,
    ToggleItem,
    CommoditySubGroupTree,
    SelectElementWithContentModal,
    DropdownEllipsis
  },
  props: {
    commodityGroup: {
      type: Object,
      required: true
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data: () => ({
    isOpen: false as boolean,
    isOpenProductGroupParamModal: false as boolean,
  }),
  methods: {
    async importParams(pgId: any) {
      const formData = new FormData();
      const container = document.getElementById('product-group') as any
      const input = document.createElement('input')

      input.type = 'file'
      input.id = 'input-load-file'
      input.style.display = 'none'
      container.appendChild(input);

      const createdInput = document.getElementById('input-load-file') as any
      createdInput.click()

      createdInput.addEventListener('change', async (event: any) => {
        const file = event.target.files[0]
        formData.append('file', file)
        formData.append('pgId', pgId)

        try {
          await params.importParams(formData)
          this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
        } catch (error) {
          this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR')
        }
      })

      container.removeChild(createdInput)
    },
    goToPageDownloadTemplate(pgId: any) {
      window.open(params.exportTemplateParam(pgId), '_blank');
    },
    openProductGroupParamModal() {
      this.isOpenProductGroupParamModal = true
      setTimeout(() => {
        let element = document.getElementById(`modal-product-group-param`)!;
        element.style.display = 'flex'
      })
    },
    closeProductGroupParamModal() {
      this.isOpenProductGroupParamModal = false
      let element = document.getElementById(`modal-product-group-param`)!;
      element.style.display = 'none'
    },
    async deleteCommodityGroup() {
      try {
        await product.deleteProductGroup(this.commodityGroup.id)
        this.$emit('delete', this.commodityGroup)
        this.closeModalConfirmDelete()
      } catch (e) {}
    },
    openModalTecDocConnections() {
      this.$emit('openTecDocConnections', this.commodityGroup)
    },
    openModalConfirmDelete() {
      let element = document.getElementById(`modal-select-element-with-content-${this.commodityGroup.id}`)!;
      element.style.display = 'flex'
    },
    closeModalConfirmDelete() {
      let element = document.getElementById(`modal-select-element-with-content-${this.commodityGroup.id}`)!;
      element.style.display = 'none'
    },
    editCommodityGroup() {
      this.$emit('editCommodityGroup', this.commodityGroup)
    },
    async changeVisible() {
      const isVisible = this.commodityGroup.visible
        ? 'INVISIBLE'
        : 'VISIBLE'

      const newCommodityGroup = await product.changeStatus(this.commodityGroup.id, isVisible)
      this.$emit('replace', newCommodityGroup)
    }
  },
})
