
import { defineComponent } from 'vue';
import ToggleItem from '@/components/commodity-group-tree/ToggleItem.vue';
import SystemSubGroups from '@/components/system-product-groups/SystemSubGroups.vue';
import BaseUpdateItemModal from '@/components/modals/base-modals/BaseUpdateItemModal.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import AttachProductGroups from '@/components/modals/AttachProductGroups.vue';
import SystemGroups from '@/components/system-product-groups/SystemGroups.vue';
import DropdownEllipsis from '@/components/admin-panel/DropdownEllipsis.vue';
import timeService from '@/services/timeService';
import reports from '@/services/reports';
import { store } from '@/store';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';
import tree from '@/services/tree';

export default defineComponent({
  name: 'Systems',
  components: {
    DropdownEllipsis,
    SystemGroups,
    AttachProductGroups,
    BaseIcon,
    SystemSubGroups,
    ToggleItem,
    BaseUpdateItemModal,
  },
  props: {
    systems: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    systemData: {
      id: null,
      name: null,
    } as any
  }),
  computed: {
    isEditSystem(): boolean {
      return !!this.systemData && this.systemData.id
    },
    getFieldName() {
      return (field: any) => {
        switch (field) {
          case 'name':
            return 'Название системы'
        }
      }
    },
  },
  methods: {
    async downloadTreeSystems() {
      try {
        const table = await tree.exportTreeSystems()
        let date = timeService.createDateForViewFromUTCTime();
        const blob = new Blob([table], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}) as any;
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `Системы_${date}.xls`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
      }
    },
    deleteSystem(systemData: any) {
      this.$store.dispatch('deleteSystem', systemData.system.id)
    },
    editSystem(systemData: any) {
      this.systemData = {...systemData.system};
      this.openModal('modal-update-system')
    },
    setDefault() {
      this.systemData = { id: null, name: null }
    },
    closeModal(id: string) {
      const modal = document.getElementById(id)!
      modal.style.display = 'none'
      this.setDefault()
    },
    openModal(id: string) {
      const modal = document.getElementById(id)!
      modal.style.display = 'flex'
    },
    async updateSystem({ name, id }: { name: string, id: number }) {
      const dispatchAction = this.isEditSystem
        ? 'updateSystemProductGroups'
        : 'addSystemProductGroups'

      const payload = {
        name,
        ...(id && { id })
      }

      await this.$store.dispatch(dispatchAction, payload).finally(() => {
        this.closeModal(`modal-update-system`)
      })
    }
  },
})
