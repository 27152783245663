
import {defineComponent, computed} from 'vue';
import Spinner from '@/components/Spinner.vue';
import BaseIcon from "@/components/ui/BaseIcon.vue";
import CommodityGroupsModal from "@/components/modals/CommodityGroupsModal.vue";
import {ArticleMutationTypes} from "@/store/modules/articles/mutation-types";
import product from "@/services/product";
import AddCommodityGroupModal from "@/components/modals/AddCommodityGroupModal.vue";
import CommodityGroupTree from '@/components/commodity-group-tree/CommodityGroupTree.vue';
import DropdownSearchCommodityGroups
  from '@/components/dropdown/DropdownSearchCommodityGroups.vue';
import TecDocConnectionsModal from '@/components/modals/TecDocConnectionsModal.vue';
import { store } from '@/store';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';

export default defineComponent({
  name: 'CommodityGroupsSettings',
  components: {
    TecDocConnectionsModal,
    CommodityGroupTree,
    Spinner,
    BaseIcon,
    CommodityGroupsModal,
    AddCommodityGroupModal,
    DropdownSearchCommodityGroups
  },
  data: () => ({
    searchLetter: 'Все' as any,
    isFoundCommodityGroups: false as boolean,
    pageable: null as any,
    currentGroup: null as any,
    isLoading: true as boolean,
    commodityGroups: [] as any,
    arrSequentialValues: ['А', 'Б', 'В', 'Г', 'Д', 'Е', 'Ё', 'Ж', 'З', 'И', 'Й', 'К', 'Л', 'М', 'Н', 'О', 'П', 'Р', 'С', 'Т', 'У', 'Ф', 'Ч', 'Ц', 'Ш', 'Щ', 'Ъ', 'Ы', 'Ь', 'Э', 'Ю', 'Я'],
    isShowModalTecDocConnections: false
  }),
  methods: {
    async setTecdocIds(pgIds: Array<number | string>) {
      try {
        const newProductGroup = await product.setTecdocIds(this.currentGroup.id, pgIds)
        this.replaceCommodityGroups(newProductGroup)
        this.closeModalTecDocConnections()
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS');
      } catch (error) {
        store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR');
      }
    },
    closeModalTecDocConnections() {
      this.isShowModalTecDocConnections = false
      this.currentGroup = null
      let element = document.getElementById(`modal-tec-doc-connections`)!;
      element.style.display = 'none'
    },
    openModalTecDocConnections(group: any){
      this.isShowModalTecDocConnections = true
      this.currentGroup = group

      setTimeout(() => {
        let element = document.getElementById(`modal-tec-doc-connections`)!;
        element.style.display = 'flex'
      }, 0)
    },
    async setFoundCommodity(data: {inputValue: string, searchResult: any}) {
      if (!data.inputValue.length) {
        this.commodityGroups = await this.getCommodityGroups()
      } else {
        this.commodityGroups = data.searchResult
      }
    },
    setNewCommodityGroup(commodityGroup: any) {
      this.commodityGroups.push(commodityGroup)
      this.closeModalAddCommodityGroup()
    },
    openModalAddCommodityGroup() {
      let element = document.getElementById(`modal-add-commodity-group`)!
      element.style.display = 'flex'
    },
    closeModalAddCommodityGroup() {
      let element = document.getElementById(`modal-add-commodity-group`)!
      element.style.display = 'none'
    },
    previous() {
      if (!!this.pageable.pageNumber) {
        if (this.searchLetter && this.searchLetter !== 'Все') {
          this.setCommodityGroups(this.pageable.pageNumber - 1, {
            action: 'SEARCH_BY_LETTER',
            letter: this.searchLetter
          })

          return
        }
        this.setCommodityGroups(this.pageable.pageNumber - 1)
      }
    },
    next () {
      if ((this.pageable.pageNumber + 1) !== this.pageable.totalPages) {
        if (this.searchLetter && this.searchLetter !== 'Все') {
          this.setCommodityGroups(this.pageable.pageNumber + 1, {
            action: 'SEARCH_BY_LETTER',
            letter: this.searchLetter
          })

          return
        }
        this.setCommodityGroups(this.pageable.pageNumber + 1)
      }
    },
    deleteCommodityGroup(newCommodityGroup: any) {
      const index = this.commodityGroups.findIndex((el: any) => el.id === newCommodityGroup.id)
      this.commodityGroups.splice(index, 1)
    },
    replaceCommodityGroups(newCommodityGroup: any) {
      const index = this.commodityGroups.findIndex((el: any) => el.id === newCommodityGroup.id)
      this.commodityGroups.splice(index, 1, newCommodityGroup)
    },
    openCommodityGroupsModal(group: any) {
      this.currentGroup = group
      setTimeout(() => {
        let element = document.getElementById(`modal-commodity-groups-edit`)!
        element.style.display = 'flex'
      }, 0)
    },
    closeCommodityGroupsModal() {
      let element = document.getElementById(`modal-commodity-groups-edit`)!
      element.style.display = 'none'
    },
    async getCommodityGroups(page = 0, data?: any) {
      if (data && data.action === 'SEARCH_BY_LETTER') {
        const response = await product.findProductGroupByDesc(data.letter, page).then((res: any) => res.data)

        this.pageable = {
          ...response.pageable,
          totalPages: response.totalPages
        }

        return response.content
      }

      const newCommodityGroups = await product.findAll(page).then((response: any) => {
        this.pageable = {
          ...response.pageable,
          totalPages: response.totalPages
        }

        return response.content
      })

      return newCommodityGroups
    },
    async setCommodityGroups(page = 0, data?: any) {
      this.isFoundCommodityGroups = false
      this.isLoading = true
      this.commodityGroups = await this.getCommodityGroups(page, data)
      this.isLoading = false
    },
    setFoundCommodityGroups(newCommodityGroups: any) {
      console.log('New Commodity Groups', newCommodityGroups)
      this.isFoundCommodityGroups = true
      this.commodityGroups = newCommodityGroups
    },
    clearFoundCommodityGroups() {
      this.setCommodityGroups(this.pageable.pageNumber)
      this.searchLetter = 'Все'
    },
    async setSearchByLetter(letter: string) {
      this.searchLetter = letter
      this.setCommodityGroups(0, {
        action: 'SEARCH_BY_LETTER',
        letter
      })

    },
    clearSearchLetter() {
      this.searchLetter = 'Все'
      this.setCommodityGroups(0)
    },
    goToPage(url: string, group: any) {
      console.log(group)
      this.$store.commit(ArticleMutationTypes.SET_GROUP, group.desc || '')

      localStorage.setItem('group', JSON.stringify({
        id: group.id,
        value: group.desc
      }))

      this.$router.push(url)
    }
  },
  async mounted() {
    this.setCommodityGroups()
  },
})
