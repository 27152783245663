
import { defineComponent } from 'vue';
import ModalWrapper from './ModalWrapper.vue';

export default defineComponent({
  name: 'SelectElementWithContentModal',
  components: {
    ModalWrapper
  },
  props: {
    mainContent: {
      type: String,
      required: false,
      default: () => ''
    },
    contentText: {
      type: String,
      required: false,
      default: () => ''
    },
    settingButtons: {
      type: Array,
      required: true,
      default: () => null
    },
    id: {
      type: String,
      required: false
    }
  },
  computed: {
    modalWrapperId(): string {
      return this.id
        ? `select-element-with-content-${this.id}`
        : `select-element-with-content`
    }
  },
  data() {

  },
  methods: {

  },
  mounted() {
  }
})
