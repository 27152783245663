
import { defineComponent } from 'vue';
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import AddParamModal from '@/components/modals/AddParamModal.vue';
import params from '@/services/params';
import Spinner from '@/components/Spinner.vue';
import BaseIcon from '@/components/ui/BaseIcon.vue';
import CommodityGroupsParamModal from '@/components/modals/CommodityGroupsParamModal.vue';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';

export default defineComponent({
  name: 'ProductGroupParam',
  components: {
    CommodityGroupsParamModal,
    Spinner,
    ModalWrapper,
    AddParamModal,
    BaseIcon,
  },
  props: {
    productGroup: {
      type: Object,
      default: () => {}
    },
    isEdit: Boolean
  },
  data: () => ({
    paramsByProductGroup: [] as any,
    showAddParamModal: false as boolean,
    showEditParamModal: false as boolean,
    isLoading: true as boolean,
    selectedParam: null as any
  }),
  computed: {
    getParamName() {
      return (param: any) => {
        return `
          ${param.name}
          ${
            param && param.units
              ? `(${param.units})`
              : ''
          }
        `
      }
    }
  },
  methods: {
    async saveQueue(successAlert = false) {
      const payload: Record<string, any> = {}

      this.paramsByProductGroup.map((param: any, index: number) => {
        payload[param.id] = index
      })

      try {
        await params.setQueue(this.productGroup.id, payload)
        if (successAlert) {
          this.closeOpenedModal()
          this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
        }
      } catch (error) {
        this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR')
      }
    },
    async setDefaultQueue() {
      const DEFAULT_VALUE_QUEUE = 1000
      const isEveryParamQueueNumberEqual = this.paramsByProductGroup.every((param: any) => {
        return param.queueNumber === DEFAULT_VALUE_QUEUE
      })

      if (isEveryParamQueueNumberEqual) {
        await this.saveQueue()
      }
    },
    findParam(paramId: number) {
      return this.paramsByProductGroup.findIndex((param: any) => {
        return param.id === paramId
      })
    },
    increaseQueue(param: any) {
      const paramIndex = this.findParam(param.id)

      const prevParam = this.paramsByProductGroup[paramIndex - 1]

      this.paramsByProductGroup.splice(paramIndex, 1, {
        ...prevParam,
        queueNumber: param.queueNumber
      })

      this.paramsByProductGroup.splice(paramIndex - 1, 1, {
        ...param,
        queueNumber: prevParam.queueNumber
      })

      this.sortProductGroups()
    },
    decreaseQueue(param: any) {
      const paramIndex = this.findParam(param.id)

      const nextParam = this.paramsByProductGroup[paramIndex + 1]

      this.paramsByProductGroup.splice(paramIndex, 1, {
        ...nextParam,
        queueNumber: param.queueNumber
      })

      this.paramsByProductGroup.splice(paramIndex + 1, 1, {
        ...param,
        queueNumber: nextParam.queueNumber
      })

      this.sortProductGroups()
    },
    setParam(editedParam: any) {
      const indexParam = this.paramsByProductGroup.findIndex((param: any) => param.id === editedParam.id)
      this.paramsByProductGroup.splice(indexParam, 1, editedParam)
      this.sortProductGroups()
    },
    closeOpenedModal() {
      let element = document.getElementById(`modal-product-group-param`)!
      element.style.display = 'none'
    },
    closeEditParamModal() {
      this.selectedParam = null
      this.showEditParamModal = false

      let element = document.getElementById(`modal-commodity-groups-param-edit`)!
      element.style.display = 'none'
    },
    openEditParamModal(param: any) {
      this.selectedParam = param
      this.showEditParamModal = true
      setTimeout(() => {
        let element = document.getElementById(`modal-commodity-groups-param-edit`)!
        element.style.display = 'flex'
      })
    },
    async deleteParam(currentParam: any) {
      try {
        await params.deleteParam(currentParam.id)
        const indexParam = this.paramsByProductGroup.findIndex((param: any) => param.id === currentParam.id)
        this.paramsByProductGroup.splice(indexParam, 1)
      } catch (e) {}
    },
    addParam(newParam: any) {
      this.paramsByProductGroup.push(newParam)
      this.showAddParamModal = false
    },
    close() {
      this.showAddParamModal = false
    },
    open() {
      this.showAddParamModal = true
      setTimeout(() => {
        let element = document.getElementById(`modal-add-param-group`)!
        element.style.display = 'flex'
      })
    },
    sortProductGroups() {
      this.paramsByProductGroup = this.sortItemsByQueue(this.paramsByProductGroup)
    },
    sortItemsByQueue(items: any) {
      return items.sort((firstItem: any, secondItem: any) => {
        return firstItem.queueNumber - secondItem.queueNumber;
      });
    }
  },
  async mounted() {
    this.paramsByProductGroup = this.sortItemsByQueue(
      await params.getAllByProductGroup(this.productGroup && this.productGroup.id)
    )

    if (this.paramsByProductGroup?.length) {
      await this.setDefaultQueue()
    }

    this.isLoading = false
  }
})
