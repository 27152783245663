
import ModalWrapper from '@/components/modals/ModalWrapper.vue';
import { defineComponent } from 'vue';
import SearchDropdown from '@/components/search/SearchDropdown.vue';
import product from '@/services/product';
import BaseError from '@/components/ui/BaseError.vue';
import { MessageModalMutationTypes } from '@/store/modules/message-modal/mutation-types';

export default defineComponent({
  name: 'AttachProductGroups',
  components: { ModalWrapper, SearchDropdown, BaseError },
  data: () => ({
    search: {
      SYSTEM: null,
      PRODUCT_GROUP: null,
      PRODUCT_SUB_GROUP: null,
      PRODUCT_SUB_SUB_GROUP: null,
    } as any,
    selectAllGroups: false
  }),
  computed: {
    attachError(): string | null {
      return this.$store.getters.getErrorAttachSystem
    },
    isDisabledBtn(): boolean {
      return !(this.search.SYSTEM && this.search.PRODUCT_GROUP)
    },
    isDisabled(): any {
      return {
        productSubGroup: !this.search.PRODUCT_GROUP || this.selectAllGroups,
        productSubSubGroup: !this.search.PRODUCT_SUB_GROUP || this.selectAllGroups,
      }
    },
  },
  methods: {
    setDefault() {
      this.selectAllGroups = false
      this.search = {
        SYSTEM: null,
        PRODUCT_GROUP: null,
        PRODUCT_SUB_GROUP: null,
        PRODUCT_SUB_SUB_GROUP: null,
      }
    },
    closeModal() {
      const modal = document.getElementById('modal-attach-product-groups')!
      modal.style.display = 'none'
      this.setDefault()
    },
    switchSelectAllGroup() {
      this.selectAllGroups = !this.selectAllGroups
      this.search.PRODUCT_SUB_GROUP = null
      this.search.PRODUCT_SUB_SUB_GROUP = null
    },
    async attachGroups() {
      let pgs;

      if (this.selectAllGroups) {
        const { content: subGroups } = await product.findProductSubGroupByNameAndProductId('', this.search.PRODUCT_GROUP.id)
        pgs = subGroups.length
          ? subGroups.map((subGroup: any) => ({
            pgId: this.search.PRODUCT_GROUP.id,
            psgId: subGroup.id,
            pssgId: null
          }))
          : [{
            pgId: this.search.PRODUCT_GROUP.id,
            psgId: null,
            pssgId: null
          }]
      } else {
        pgs = [{
          pgId: this.search.PRODUCT_GROUP.id,
          psgId: this.search.PRODUCT_SUB_GROUP?.id || null,
          pssgId: this.search.PRODUCT_SUB_SUB_GROUP?.id || null,
        }]
      }

      try {
        await this.$store.dispatch('attachSystem', {
          systemId: this.search.SYSTEM.id,
          pgs
        })
        await this.$store.dispatch('fetchSystemsGroups')
        this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'SUCCESS')
        this.closeModal()
      } catch (error) {
        if (error && error.data) {
          this.$store.commit('setErrorAttachSystem', error.data.message)
        } else {
          this.$store.commit(MessageModalMutationTypes.OPEN_MESSAGE_MODAL, 'ERROR')
        }
      }
    },
    update(type: any, item: any) {
      if (type === 'SYSTEM') {
        this.search.SYSTEM = item
      }
      if (type === 'PRODUCT_GROUP') {
        this.search.PRODUCT_GROUP = item
        this.search.PRODUCT_SUB_SUB_GROUP = null
        this.search.PRODUCT_SUB_GROUP = null
        this.selectAllGroups = false
      }

      if (type === 'PRODUCT_SUB_GROUP') {
        this.search.PRODUCT_SUB_GROUP = item
        this.search.PRODUCT_SUB_SUB_GROUP = null
      }

      if (type === 'PRODUCT_SUB_SUB_GROUP') {
        this.search.PRODUCT_SUB_SUB_GROUP = item
      }
    }
  },
})
